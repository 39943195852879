<template>
    <div class="main-wrapper">
        <el-scrollbar class="main-wrapper-scrollbar">
            <div class="wrapper-list">
                <div class="top-title">
                    <img class="title-left" src="../../../../assets/images/student/line-left.png" alt="">
                    <span class="title">{{op_name}}</span>
                    <img class="title-right" src="../../../../assets/images/student/line-right.png" alt="">
<!--                    <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>-->
                </div>
                <img src="../../../../assets/images/student/bottom-line.png" alt="" style="width: 100%;">
                <div class="time-item">
                    <div class="time-item-list">
                        <div class="time-title-left">
                            <span class="comma" style="margin-left: 22px">,,,</span>
                            <div class="item">
                                <span class="score-title">本题分值：{{score}}分</span>
                                <span style="margin-left: 50px">考核时间{{assess_time}}分钟</span>
                            </div>
                            <span class="comma">,,,</span>
                        </div>
                        <div class="start-tex" @click="startBtn()" v-if="this.$route.query.id">开始训练</div>
                        <div class="start-tex" @click="startBtn()" v-else>开始考试</div>
                    </div>
                </div>
                <!--S 任务目标-->
                <!-- <div class="mission-wrapper">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务目标</span>
                    </div>
                    <div class="task-item" v-for="(item,index) in taskGoal" :key="index">
                        <div class="task-bg">
                            <img src="../../../../assets/images/student/task-bg.png" alt="">
                            <p class="num-title"><em style="color: #FEDB2E">N0.</em>任务{{index + 1}}</p>
                        </div>
                        <div class="task-content">
                            <p class="text">{{item.target}}</p>
                        </div>
                    </div>
                </div> -->
                <!--E 任务目标-->
                <!--S 任务背景-->
                <div class="mission-wrapper">
                    <!-- <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">操作要求</span>
                    </div> -->
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="task_background"></p>
                        </div>
                    </div>
                </div>
                <!--E 任务背景-->
                <!--S 任务分析-->
                <div class="mission-wrapper" v-if="this.$route.query.id">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务分析</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="task_analysis"></p>
                        </div>
                    </div>
                </div>
                <!--E 任务分析-->
                <!--S 任务操作-->
                <div class="mission-wrapper" v-if="this.$route.query.id">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务操作</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="task_operation"></p>
                        </div>
                    </div>
                </div>
                <!--E 任务操作-->
                <!--S 评分标准-->
                <div class="mission-wrapper" v-if="this.$route.query.id">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">评分标准</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <div class="task-table">
                                <div class="table-title">
                                    <span class="text">序号</span>
                                    <span class="text">考核内容</span>
                                    <span class="text">考核要点</span>
                                    <span class="text">配分</span>
                                    <span class="text">评分标准</span>
                                </div>
                                <div class="table-body">
                                    <div class="body-item" v-for="item in scoringCriteria">
                                        <span class="text">{{item.index}}</span>
                                        <span class="text">{{item.content}}</span>
                                        <span class="text">{{item.require}}</span>
                                        <span class="text">{{item.score}}</span>
                                        <span class="text">{{item.standard}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--E 评分标准-->
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {stuOpGetExamInfo} from '@/utils/apis'
    import {mapGetters} from 'vuex'

    export default {
        name: "Index",
        data() {
            return {
                type: '',
                op_name: '',//网店管理名称
                score: '',//分数
                assess_time: '',//时间
                taskGoal:[],//任务目标
                task_background: '',//任务背景
                task_analysis: '',//任务分析
                task_operation: '',//任务操作
                scoringCriteria:[],//评分标准
                exam_module_id: '',//模块id
            }
        },
        mounted() {
            this.getExamStuExamPage();
        },
        methods: {
            // 开始考试
            startBtn() {
                //设置exam_module_id保存到localStorage中
                localStorage.setItem('exam_module_id', this.exam_module_id);
                if (this.$route.query.id) {
                    //要判断是哪种类型的试卷
                    if (this.exam_module_id === 8 || this.exam_module_id === 58) {
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/goodsInfo/hotGoods',
                            query: {
                                id: this.$route.query.id,
                                exam_module_id: this.exam_module_id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.exam_module_id === 9) {
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/goodsInfo/goodsShoot',
                            query: {
                                id: this.$route.query.id,
                                exam_module_id: this.exam_module_id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.exam_module_id === 10) {
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/goodsInfo/goodsVideoShoot',
                            query: {
                                id: this.$route.query.id,
                                exam_module_id: this.exam_module_id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }

                } else {
                    //要判断是哪种类型的试卷
                    if (this.exam_module_id === 8 || this.exam_module_id === 58 ) {
                       let routeDate = this.$router.resolve({
                           path: '/student/examing/goodsInfo/hotGoods',
                           query: {
                             id: this.$route.query.id,
                             exam_module_id: this.exam_module_id
                           }
                       })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.exam_module_id === 9) {
                        this.$router.push({
                            path: '/student/examing/goodsInfo/goodsShoot',
                            query: {
                                id: this.$route.query.id,
                                exam_module_id: this.exam_module_id
                            }
                        })
                        // window.open(routeDate.href, '_blank')
                    }
                    if (this.exam_module_id === 10) {
                        this.$router.push({
                            path: '/student/examing/goodsInfo/goodsVideoShoot',
                            query: {
                                id: this.$route.query.id,
                                exam_module_id: this.exam_module_id
                            }
                        })
                        // window.open(routeDate.href, '_blank')
                    }
                }
            },
            goBack(){
                this.$router.go(-1)
            },
            getExamStuExamPage() {
                let p = {
                    type: "goods_info_collect"
                }
                if (this.$route.query.id) {
                    p.op_id = this.$route.query.id;
                }
                stuOpGetExamInfo(p).then((res) => {
                    this.exam_module_id = res.data.exam_module_id;
                    this.op_name = res.data.op_name
                    this.score = res.data.score
                    this.assess_time = res.data.assess_time
                    this.taskGoal = res.data.task_goal
                    this.task_background = res.data.task_background
                    this.task_analysis = res.data.task_analysis
                    this.task_operation = res.data.task_operation
                    this.scoringCriteria = res.data.scoring_criteria
                }).catch((err) => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style scoped lang="scss">

    .main-wrapper {
        background: url("../../../../assets/images/student/student-sct-bg.png") no-repeat;

        background-size: 100% 100%;
        height: 100%;
        position: relative;
        .main-wrapper-scrollbar {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .wrapper-list {
                .top-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    font-size: 48px;
                    color: #35c773;
                    padding-top: 20px;
                    padding-bottom: 5px;
                    letter-spacing: 4px;
                    font-weight: bold;
                    position: relative;
                    .title {
                        min-height: 70px;
                        line-height: 1.2;
                    }
                    .title-left {
                        margin-right: 50px;
                    }
                    .title-right {
                        margin-left: 50px;
                    }
                    .backBtn{
                        position: absolute;
                        right: 40px;
                        top: 0;
                    }

                }
                .time-item {
                    /*position: absolute;*/
                    /*top: 180px;*/
                    margin-top: 80px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .time-item-list {
                        display: flex;
                        height: 80px;
                        line-height: 80px;
                        background: #39B678;

                        .time-title-left {
                            flex: 1;
                            display: flex;
                            border: 6px solid #61a885;
                            background: #DDFDF2;

                            .comma {
                                color: #31A588;
                                font-size: 66px;
                                letter-spacing: 18px;
                                text-align: center;
                                line-height: 33px;
                                display: inline-block;
                            }

                            .item {
                                flex: 1;
                                font-size: 24px;
                                padding: 0 40px;
                                color: #31A588;
                                position: relative;

                                &:after {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    right: 10px;
                                }

                                &:before {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    left: 10px;
                                }
                            }
                        }

                        .start-tex {
                            width: 120px;
                            font-size: 24px;
                            color: #fff;
                            cursor: pointer;
                            text-align: center;
                        }
                    }

                }
            }
            .mission-wrapper {
                width: 100%;
                .mission-title {
                    width: 100%;
                    height: 80px;
                    text-align: center;
                    font-size: 48px;
                    font-style: italic;
                    font-weight: 600;
                    position: relative;
                    margin-top: 40px;

                    .line {
                        position: absolute;
                        top: 40px;
                        left: calc(50% - 150px);
                        width: 300px;
                        height: 20px;
                        background: #FEDB2E;
                    }

                    .mission-title-text {
                        position: inherit;
                        /*left: calc(50% - 100px);*/
                        text-align: center;
                    }
                }

                .task-item {
                    margin-top: 40px;
                    .task-bg {
                        color: #fff;
                        position: relative;
                        margin-left: 50px;

                        .num-title {
                            font-size: 34px;
                            position: absolute;
                            top: -26px;
                            left: 50px;

                            em {
                                font-style: normal;
                            }

                        }
                        .title {
                            color: rgb(255, 255, 255);
                            font-size: 30px;
                            position: absolute;
                            top: -22px;
                            left: 126px;
                        }
                    }

                    .task-content {
                        background: #DDFDF2;
                        padding: 20px 20px 20px 50px;
                        margin: -20px 20px;
                        ::v-deep p img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                        .text {
                            ::v-deep p span {
                                background-color: transparent !important;
                            }
                            ::v-deep table {
                                tr {
                                    height: 50px;
                                    line-height: 50px;
                                    td {
                                        border: 1px solid #D2D2D2;
                                        border-right: 2px solid #fff;
                                        text-align: center;
                                        font-size: 14px;
                                        color: #333;
                                        &:first-child {
                                            border-right: 2px solid #fff;
                                        }
                                        &:last-child {
                                            border-right:  1px solid #D2D2D2;
                                        }
                                    }
                                    &:nth-child(2n) {
                                        td {
                                            background: #F2F9FA;
                                        }
                                    }
                                    &:nth-child(2n-1) {
                                        td {
                                            background: #C4F2E4;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .task-table {
        border: 1px solid #D2D2D2;
        margin-top: 16px;
        background-color: #fff;
        .table-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 50px;
            line-height: 50px;
            background: #C4F2E4;
            box-sizing: border-box;
            margin-top: 9px;
        }
        .text {
            display: inline-block;
            font-size: 16px;
            color: #333;
            text-align: center;
            border-right: 2px solid #fff;
            &:nth-child(1) {
                width: 80px;
            }
            &:nth-child(2) {
                width: 200px;
            }
            &:nth-child(3) {
                width: 300px;
            }
            &:nth-child(4) {
                width: 80px;
            }
            &:nth-child(5) {
                flex: 1;
                width: 1%;
            }
            &:last-child {
                border-right: none;
            }
        }
        .table-body {
            display: flex;
            flex-direction: column;
            .body-item {
                display: flex;
                justify-content: space-between;
                height: 60px;
                line-height: 60px;
                background-color: #C4F2E4;
                &:nth-child(2n-1) {
                    background-color: #F2F9FA;
                }
                &:last-child {
                    margin-bottom: 9px;
                }
            }
        }
    }
</style>